import {
	getMapsHelper
} from './util/mapsHelper';

let submitInProgress = false;

const updateResponseMessage = (errors) => {
	var response = '';
	console.log(errors);
	if (errors.length === 1) {
		response = "The " + errors[0] + " field is required.";
	} else if (errors.length === 2) {
		response = "The " + errors[0] + " and " + errors[1] + " fields are required.";
	} else {
		let fields = "";
		for (let x = 0; x < errors.length; x++) {
			fields += errors[x];
			if (x < errors.length - 1) {
				fields += ', ';
			}
			if (x === errors.length - 2) {
				fields += 'and ';
			}
		}
		response = "The " + fields + " fields are required.";
	}
	document.querySelector('.response_message').innerHTML = "<span class='error'>" + response + "</span>";
};

/**
 * 
 * @param {Object} param0 Object of named parameters
 * 
 * elementSelector : the css selector for the form element
 * required : (String[]) 
 */
const _listenToForm = ({
	elementSelector = '#contact_form',
	required = ['name', 'email', 'message']
} = {}) => {

	document.querySelector(elementSelector).addEventListener('submit', function(e) {
		if (document.getElementById('hp').value !== '') return;
		const formEl = document.querySelector(elementSelector);
		Array.prototype.forEach.call(formEl.querySelectorAll('.error'), function(error) {
			error.classList.remove('error');
		});
		formEl.querySelector('.response_message').innerHTML = '';

		if (submitInProgress) {
			return;
		}

		// var checkFields = new Array('name', 'email', 'message');
		let errorsFound = new Array();
		required.forEach(function(item) {
			if (document.getElementById(item + '_input').value === '') {
				document.getElementById(item + '_input').classList.add('error');
				errorsFound.push(item);
			}
		});
		if (!submitInProgress && errorsFound.length === 0) {
			submitInProgress = true;

			let request = new XMLHttpRequest(),
				method = 'POST',
				url = formEl.getAttribute('action'),
				data = new FormData(formEl);

			request.onreadystatechange = function() {
				if (request.readyState === 4 && request.status === 200) {
					let response = JSON.parse(request.responseText);
					if (response.ret_det.success) {
						let input = document.getElementById('contact_form_submit');
						input.parentElement.removeChild(input);
						formEl.querySelector('.response_message').innerHTML = "<span class='success'>" + response.ret_det.message + "</span>";
					} else {
						if (typeof response.data.error_fields !== 'undefined') {
							let errors = [];
							for (let x = 0; x < response.data.error_fields.length; x++) {
								errors.push(response.data.error_fields[x].field_name);
							}
							updateResponseMessage(errors);
						} else {
							formEl.querySelector('.response_message').innerHTML = "<span class='error'>" + response.ret_det.message + "</span>";
						}
					}
					submitInProgress = false;
				}
			};
			request.open(method, url, true);
			request.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
			request.send(data);
		}
		else if (errorsFound.length > 0) {
			updateResponseMessage(errorsFound);
		}
		else {
			formEl.querySelector('.response_message').innerHTML = "<span class='error'>There was a problem processing your request.</span>";
		}
		e.stopImmediatePropagation();
		e.preventDefault();
	});


};

const _createEl = ({
	innerHTML
}) => {
	const template = document.createElement('template');
	template.innerHTML = innerHTML;
	console.log(template);
	return template.content.children[0];
};


const stateNames = {
	CA: "California",
	AZ: "Arizona",
	CO: "Colorado"
};
const contactFacilityData = window.contactFacilityData || [];
let selectedState = "";
let citySel = document.querySelector('#city_input');
let facilitySel = document.querySelector('#facility_linput');
const facilitySecondSelect = document.querySelector('#facility_select');
const mapClose = document.querySelector('#contact_map_close');
const contactMapTrigger = document.querySelector('#contact_map_trigger');
const mapCanvas = document.querySelector('#contact_map_canvas');
let map;

let getCitiesForState = state => {
	let cities = contactFacilityData.filter(element => element.state === state);
	return cities;
};

let createOptionItem = value => {
	let temp = document.createElement('div');
	temp.innerHTML = `<option value="${value}">${value}</option>`;
	return temp.children[0];
};

let stateClick = (state) => {
	citySel.innerHTML = "";
	citySel.removeAttribute('disabled');
	if (document.querySelector('label[disabled][for="city_input"]')) { //if disabled label exists
		document.querySelector('label[disabled][for="city_input"]').removeAttribute('disabled');
	}
	facilitySel.innerHTML = "";
	if (document.querySelector(`.contact_state_btn[active]`)) {
		document.querySelector(`.contact_state_btn[active]`).removeAttribute('active');
	}
	document.querySelector(`.contact_state_btn[data-state="${state}"]`).setAttribute('active', '');
	selectedState = state;
	let cities = getCitiesForState(state);
	let uniqueCities = [...new Set(cities.map(element => element.city))];
	citySel.appendChild(createOptionItem(""));
	uniqueCities.forEach(city => {
		citySel.appendChild(createOptionItem(city));
	});
};

//city selection handler
let citySelected = (city) => {
	if (!city) { return; }

	let cities = getCitiesForState(selectedState);
	// cities.sort((a, b) => { return a.street })
	facilitySel.innerHTML = "";
	facilitySel.removeAttribute('disabled');
	if (document.querySelector("label[for='facility_linput'][disabled]")) { //if disabled exists
		document.querySelector("label[for='facility_linput'][disabled]").removeAttribute('disabled');
	}
	facilitySel.appendChild(createOptionItem(""));

	cities = cities.filter(cityEl => city === cityEl.city);
	cities.forEach(cityItem => {
		facilitySel.appendChild(createOptionItem(cityItem.name));
	});
};

const _changeEvt = (e) => {
	const select = e.currentTarget;
	const latlng = {
		lat: +select.options[select.selectedIndex].getAttribute('lat'),
		lng: +select.options[select.selectedIndex].getAttribute('lng')
	};
	map.setCenter(latlng);
};

const facilitySelected = facilityStreet => {
	if (!facilityStreet) {
		// return;//empty value selected @todo what needs to happen
	}

	document.querySelector('#contact_form_submit').removeAttribute('disabled');

	let facilArr = contactFacilityData.filter(el => el.name === facilityStreet);
	if (facilArr.length === 1) {
		let facility = facilArr.pop();
		// document.getElementById('facility_id_input').value = facility.id;
		//@TODO ADD FACILITTY

		document.querySelector('#contact_facility_details [state]').innerHTML = stateNames[facility.state];
		document.querySelector('#contact_facility_details [city]').innerHTML = facility.city;
		document.querySelector('#contact_facility_details [facility]').innerHTML = facility.name;
		document.querySelector('#contact_facility_details [address]').innerHTML = `${facility.street}, ${facility.city}, ${facility.state} ${facility.zip}`;
		contactMapTrigger.classList.add('active');


		// yourSelect.options[ yourSelect.selectedIndex ].value
		facilitySecondSelect.innerHTML = "";

		let i = 1;
		contactFacilityData.filter(el => el.name === facility.name)
			.forEach(el => {
				//add elements to second select
				let map_url = facility.street + '+' + facility.city + ',+' + facility.state + '+' + facility.zip + '/@' + facility.lat + ',' + facility.lng;
				let map_link = "https://www.google.com/maps/dir//" + map_url;
				facilitySecondSelect.appendChild(_createEl({
					innerHTML: `
			<option class='contact_loc_lat_lng' index='${i++}' title='${facility.name}' map='${map_link}' lat='${el.lat}' lng='${el.lng}' value="${el.id}">${el.street}</option>
			`}));
				console.log(el);
			});

		facilitySecondSelect.value = facility.id;

		facilitySecondSelect.addEventListener('change', _changeEvt);

	} else {
		alert('An Error has occurred in retrieving the facility information.');
	}

};

// const _facilitySecondSelectChange = (e) => {
// 	const facilitySecondSelect = e.currentTarget;
// };



const _closeMap = () => {
	mapCanvas.classList.remove('active');
	mapClose.classList.remove('active');
	facilitySecondSelect.addEventListener('change', _changeEvt);
};

if (mapClose) {
	mapClose.addEventListener('click', _closeMap);
}


const _initContactMap = (() => {
	const mapHelper = getMapsHelper();

	return async () => {
		await mapHelper.ready();
		contactMapTrigger.addEventListener('click', () => {
			mapCanvas.classList.add('active');
			mapClose.classList.add('active');

			map = mapHelper.createMap({
				element: mapCanvas,
				useRichmarker: true,
				locationElementSelector: '.contact_loc_lat_lng',
				markerReducer: el_1 => {
					return {
						lat: el_1.getAttribute('lat'),
						lng: el_1.getAttribute('lng'),
						content: `
                        <a class="map_pin" href="${el_1.getAttribute('map')}" target="_blank">
							<svg xmlns="http://www.w3.org/2000/svg" width="38.66" height="55.62" viewBox="0 0 38.66 55.62"><defs><style>.cls-1{fill:#004B2A;}</style></defs><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path class="cls-1" d="M38.16,19.43c0,10.46-18.83,35.36-18.83,35.36S.5,29.89.5,19.43a18.83,18.83,0,1,1,37.66,0Z"/></g></g></svg>
                            <div><span>${el_1.getAttribute('index')}</span></div>
                        </a>
                        `
					};
				},
			});

			/* eslint-disable no-undef */
			google.maps.event.addListenerOnce(map, 'idle', () => {
				map.setZoom(14);
			});
			/* eslint-enable no-undef */
		});
	};
})();


const _contactLocationSwitch = () => {


	[...document.querySelectorAll('.contact_state_btn')].forEach(stateBtn => {
		stateBtn.addEventListener('click', e => {
			stateClick(e.currentTarget.getAttribute('data-state'));
		});
	});

	citySel.addEventListener('change', e => { citySelected(e.currentTarget.value); });
	facilitySel.addEventListener('change', e => { facilitySelected(e.currentTarget.value); });


	_initContactMap();
};

export const contactSubmit = _listenToForm;
export const contactLocationSwitch = _contactLocationSwitch;
