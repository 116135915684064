import { contactLocationSwitch, contactSubmit } from './contact.js';
import { aspFacilityCarouselStart, facilityFeats, facilityMap, viewBanner } from './facility.js';
import { footerToggle } from './footer.js';
import { helpToggle } from './help.js';
import { locationsRates } from './locations_rates.js';
import { maps } from './map.js';
import { parkingFeat } from './parking.js';
import { reviewsCaro } from './reviews.js';
import { sizeGuide } from './size_guide.js';

locationsRates.init();
footerToggle.init();
// facilityCaro.init();
aspFacilityCarouselStart();
facilityMap.init();
facilityFeats.init();
parkingFeat.init();
helpToggle.init();
sizeGuide.init();
reviewsCaro.init();
maps.init();

if (document.getElementById('contact_form')) {
	contactSubmit();

	contactLocationSwitch();
}

if(document.getElementById('facility_top_banner')){
	viewBanner.init();
}

/* eslint-disable */
let toggles = document.querySelectorAll('#toggle_disclaimer');
let disclaimers = document.querySelectorAll('#disclaimer_text');
if (document.getElementById('toggle_disclaimer')) {
	toggles.forEach(toggle => {		
		toggle.addEventListener('click', function() {
			const formWrapper = document.getElementById('form_wrapper');
			//let disclaimer = document.getElementById('disclaimer_text');
			let img = this.querySelector('img');
			disclaimers.forEach(disclaimer => {
				if (disclaimer.style.display === 'none' || disclaimer.style.display === '') {
					disclaimer.style.display = 'block';
					img.style.transform = 'rotate(0deg)';
				} else {
					disclaimer.style.display = 'none';
					img.style.transform = 'rotate(-90deg)';
				}
			});
			formWrapper.classList.toggle('extended');
		});
	});
}

/* eslint-disable */
$(function() {
	$('#mobile_menu_toggle').click(function() {
		$(this).toggleClass('open');
		$('#main_overlay').fadeToggle();
		$('#mobile_nav').toggleClass('open');
	});

	$('.pay_bill_toggle, #overlay').click(function() {
		$('#overlay').fadeToggle(function() {
			$('#pay_bill_box').velocity('scroll');
		});
	});
	$('#pay_bill_box').click(function(e) {
		e.stopPropagation();
	});
	$('#pay_bill_box .state_name').click(function() {
		$(this).next().slideToggle();
	});

	let subTimer;

	const closeSub = function() {
		$('.dropdown_content').removeClass('active').slideUp(300);
	};

	$('.open_dropdown_container').on('click', function(e) {
		$('.open_dropdown_container').removeClass('active');
		clearTimeout(subTimer);
		let target = $(this).data('dropcontent');
		$('.dropdown_content').removeClass('active');
		$(this).addClass('active');
		if ($('dropdown_container_full .active').attr('id') !== target) {
			subTimer = setTimeout(function() {
				closeSub();
				$('#' + target).slideDown(300).addClass('active');
			}, 100);
		}
	});

	$('#sub_search').blur(function() {
		subTimer = setTimeout(function() {
			if (!$('.dropdown_container_full:hover').length > 0 && !$('#sub_search').is(':focus')) {
				$('.open_dropdown_container').removeClass('active');
				closeSub();
			}
		}, 350);
	});

	$(".close_dropdown").click(function() {
		console.log("clicked");
		$(".dropdown_content").hide();
		$(".dropdown_content").removeClass("active");
		$(".open_dropdown_container").removeClass("active");
	});


	let windowSizeHeader = $(window).width();
	$(window).resize(function() {
		if ($(".open_dropdown_container").hasClass("active")) {
			if (windowSizeHeader > 768 && $(window).width() <= 768) {
				$(".dropdown_content").hide();
				$(".dropdown_content").removeClass("active");
				$(".open_dropdown_container").removeClass("active");
			}

		}
		windowSizeHeader = $(window).width();
	});
});

$('.rates_button').click(function(e) {
	$('.rates_overlay').toggleClass("open");
	var button = e.currentTarget;

	if (!button) {
		return;
	}

	const scrollHeight = $(window).scrollTop();

	var groupIdentifier = button.getAttribute('data-group');

	var overlay = $(".overlay_options[data-group='" + groupIdentifier + "']");

	var overlayHeight = overlay.height();

	var overlaySelHeight = $('.overlay_selection').height();

	if (overlay.length) {
		//>> activate the overlay
		overlay.toggleClass("open");
	} else {
		toRatesHandler(button, false);
	}
});

var toRatesHandler = (el, overlay) => {

	$('#back_button').click(function() {

		if (overlay == false) {
			$('.rates_overlay').removeClass("open");
			$('.overlay_selection').removeClass("open");
		} else {
			$('.overlay_selection').removeClass("open");
			$(overlay).toggleClass('open');
			$('.rates_overlay').addClass("open");
			overlay = false;
		}
	});

	if (el.hasAttribute('data-inquiry-only')) {
		location.href = el.getAttribute('data-inquire');
	} else {
		var rentUrl = el.getAttribute('data-rent');
		var resUrl = el.getAttribute('data-res');
		$('.rent_overlay').attr("href", rentUrl);
		$('.reserve_overlay').attr("href", resUrl);
		$('.overlay_options.open').toggleClass("open");
		$('.overlay_selection').toggleClass("open");
	}
}

$('.to_rates').click(function(e) {
	const openOverlay = $('.overlay_options.open');
	toRatesHandler(e.currentTarget, openOverlay);
	$('.overlay_selection').addClass('open');
});

//>> close the rates if the overlay behind it is clicked
$('.rates_overlay').click(function() {
	$('.rates_overlay').removeClass("open");
	$('.overlay_options').removeClass("open");
	$('.overlay_selection').removeClass("open");
});

//>> close rates overlay if "X" is clicked
$('.overlay_close').click(function() {
	$('.rates_overlay').removeClass("open");
	$('.overlay_options').removeClass("open");
	$('.overlay_selection').removeClass("open");
});
