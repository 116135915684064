export const footerToggle = (function(){
	let my = {};

	function _addDoubleClick() {
		[].forEach.call(document.querySelectorAll('.d_click'), (el) => {
			el.addEventListener('click', e => {
				if ('ontouchstart' in document.documentElement || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0) {
					if (!el.hasAttribute('clicked')) {
						e.preventDefault();
						el.setAttribute('clicked', true);
					}
				}
			});
		});
	}

	function _footer_accordion() {
		// when a button with has_drop is hovered over, add the class active to the following ul
		const has_drop = document.querySelectorAll('.has_drop');
		
		// when has_drop is hovered over console.log('hovered');
		has_drop.forEach((el) => {
			el.addEventListener('mouseover', () => {
				// get image inside button and rotate it
				const img = el.querySelector('img');
				img.style.transform = 'rotate(90deg)';
				// get the .dropdown that is a sibling of the button
				const dropdown = document.querySelector('.dropdown'); 
				dropdown.style.display = 'block';
			});
		});
	}

	my.init = function(){
		_addDoubleClick();
		_footer_accordion();
	};

	return my;
})();