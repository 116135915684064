export const sizeGuide = (function(){
	let my = {};

	my.init = function() {
		const videoInfoTitle = document.querySelector("#video_info h3");
		const videoInfoDesc = document.querySelector("#video_info p");
		const iframe = document.querySelector(".video_container iframe");

		const buttons = document.querySelectorAll(".video_items button");
		const sizeButtons = document.querySelectorAll("#video_sizes button");
		const progressBarFill = document.querySelector("#progress_bar_fill");

		function handleButtonClick(event) {
			const buttonId = event.target.id;

			let videoSource = "";
			let title = "";
			let description = "";

			switch (buttonId) {
				case "5x5_video":
					videoSource = "https://www.youtube.com/embed/LE4CIShgt4Y?si=i07ClMH1YoVKAGJI&autoplay=1";
					title = "Small 5 x 5 Self-Storage Unit";
					description = "A 5 x 5 unit at a self-storage facility typically has a total area of 25 square feet. It is approximately the size of a small walk-in closet or a standard bathroom. This unit size is ideal for storing small furniture items, boxes, seasonal decorations, or personal belongings.";
					progressBarFill.style.width = "12%";
					break;
				case "5x10_video":
					videoSource = "https://www.youtube.com/embed/N1uSGVVX7Lk?si=UbTOUJrsTNYUbeNH&autoplay=1";
					title = "Small 5 x 10 Self-Storage Unit";
					description = "A 5 x 10 unit at a self-storage facility typically has a total area of 50 square feet. It is suitable for storing the contents of a small one-bedroom apartment or several large pieces of furniture.";
					progressBarFill.style.width = "20%";
					break;
				case "10x15_video":
					videoSource = "https://www.youtube.com/embed/uLV5jDqDNoE?si=okOb4Y1TBPFFJ20k&autoplay=1";
					title = "Medium 10 x 15 Self-Storage Unit";
					description = "A 10 x 15 unit at a self-storage facility typically has a total area of 150 square feet. It is suitable for storing the contents of a two-bedroom apartment or larger furniture pieces.";
					progressBarFill.style.width = "40%";
					break;
				case "10x20_video":
					videoSource = "https://www.youtube.com/embed/6KlhiBxPMjg?si=mDfkKkwkLX4EXKnw&autoplay=1";
					title = "Medium 10 x 20 Self-Storage Unit";
					description = "A 10 x 20 unit at a self-storage facility typically has a total area of 200 square feet. It is suitable for storing the contents of a three-bedroom house or larger items such as appliances and furniture.";
					progressBarFill.style.width = "60%";
					break;
				case "10x30_video":
					videoSource = "https://www.youtube.com/embed/i-H_FkYKA4w?si=2DgM1Wk7SVYfhMVn&autoplay=1";
					title = "Large 10 x 30 Self-Storage Unit";
					description = "A 10 x 30 unit at a self-storage facility typically has a total area of 300 square feet. It is suitable for storing the contents of a four-bedroom house or larger items such as vehicles or boats.";
					progressBarFill.style.width = "80%";
					break;
				case "20x30_video":
					videoSource = "https://www.youtube.com/embed/l9QO2fkC4Ow?si=G2mrc_2JVTD2KHRM&autoplay=1";
					title = "Large 20 x 30 Self-Storage Unit";
					description = "A 20 x 30 unit at a self-storage facility typically has a total area of 600 square feet. It is suitable for storing the contents of a larger house or for commercial storage purposes.";
					progressBarFill.style.width = "100%";
					break;
				default:
					break;
			}

			iframe.src = videoSource;
			videoInfoTitle.textContent = title;
			videoInfoDesc.textContent = description;

			buttons.forEach(btn => btn.classList.remove("active"));
			event.target.classList.add("active");
		}

		function handleSizeButtonClick(event) {
			const buttonId = event.target.id;

			const videoItemContainers = document.querySelectorAll(".video_items");
			videoItemContainers.forEach(container => {
				if (container.id === buttonId + "_videos") {
					container.classList.add("active");
				} else {
					container.classList.remove("active");
				}
			});

			sizeButtons.forEach(btn => btn.classList.remove("active"));
			event.target.classList.add("active");
		}

		sizeButtons.forEach(button => {
			button.addEventListener("click", handleSizeButtonClick);
		});

		buttons.forEach(button => {
			button.addEventListener("click", handleButtonClick);
		});
	};
	return my;
})();