/* eslint-disable */
import {
	AutomatitCarousel
} from './automatit_carousel.m';
import {
	getMapsHelper
} from './util/mapsHelper';

export const facilityFeats = (function(){
	let my = {};

	function setState(elem, state) {
		elem.setAttribute('data-state', state);
	}

	my.init = function(){
		if(document.querySelector('#rates_filters')) {
			const buttons = document.querySelectorAll('#rates_filters button');
			const unitRows = document.querySelectorAll('.unit_row');
			const ratesToggle = document.querySelectorAll('.rates_toggle');
			document.querySelector('#rates_filters').addEventListener('click', function (e) {
				let clickedButton = e.target;
				while (clickedButton !== null && clickedButton.nodeName !== 'BUTTON') {
					clickedButton = clickedButton.parentElement;
				}
				if (clickedButton) {
					[].forEach.call(buttons, function (i) {
						setState(i, 'off');
						if (clickedButton.dataset.size !== 'all') {
							setState(clickedButton, 'on');
						}
					});

					const activeButton = document.querySelector('#rates_filters button[data-state="on"]');

					[].forEach.call(unitRows, function (i) {
						setState(i, 'hide');
						if ((activeButton && activeButton.dataset.size === i.dataset.size) || !activeButton) {
							setState(i, 'show');
						}
					});

					[].forEach.call(ratesToggle, function (i) {
						if (i.dataset.size === "office") {
							setState(i, 'hide');
							if (clickedButton.dataset.size === "office") {
								setState(i, 'show');
							}
						}
					});			
				}
			});
		}
	};
	return my;
})();


// export const facilityCaro = (function () {
// 	let my = {};

// 	my.init = function () {
// 		if (document.getElementById('facility_caro')) {

// 			if (window.slide_images.length > 1) {
// 				const options = {
// 					element: document.getElementById('facility_caro'),
// 					speed: 600,
// 					images: window.slide_images,
// 					intervalTiming: 6000,
// 					imagesAsBackgrounds: true,
// 					showDots: true,
// 					useCheverons: false
// 				};
// 				AutomatitCarousel(options);
// 			} else {
// 				let btns = document.getElementById('facility_caro_chevrons');
// 				btns.parentNode.removeChild(btns);
// 				const options = {
// 					element: document.getElementById('facility_caro'),
// 					speed: 600,
// 					images: window.slide_images,
// 					imagesAsBackgrounds: true,
// 					intervalTiming: 0,
// 					showDots: false
// 				};
// 				AutomatitCarousel(options);
// 			}
// 		}
// 	};

// 	return my;
// })();

export const facilityCaro = (function () {
	let my = {};

	let theCaro = document.getElementById('facility_lightbox_caro');

	my.destroy = () => {
		theCaro.innerHTML = "";
	};

	my.init = function () {
		if (theCaro) {
			if (window.slide_images.length > 1) {
				const options = {
					element: theCaro,
					speed: 600,
					images: window.slide_images,
					intervalTiming: 6000,
					imagesAsBackgrounds: true,
					showDots: false,
					useCheverons: false
				};
				AutomatitCarousel(options);
			} else {
				// let btns = document.getElementById('facility_caro_chevrons');
				// btns.parentNode.removeChild(btns);
				// const options = {
				// 	element: document.getElementById('facility_caro'),
				// 	speed: 600,
				// 	images: window.slide_images,
				// 	imagesAsBackgrounds: true,
				// 	intervalTiming: 0,
				// 	showDots: false
				// };
				// AutomatitCarousel(options);
			}
		}
	};

	return my;
})();

let lightboxClose = document.querySelector('#facility_lightbox_overlay_close');
const _aspFacilityCarouselStart = () => {
	const overlay = document.querySelector('#facility_lightbox_overlay');
	const container = document.querySelector('#facility_lightbox_container');
	if(! container){ return; }
	[...document.querySelectorAll('.facility_image_slide')]
	.forEach(el => el.addEventListener('click', (e) => {
		console.log(el);
		overlay.classList.add('active');
		container.classList.add('active');
		console.log('in caro init image loop');
		showCaro(e);
		// facilityCaro.init();
	}));

	const closeLightbox = () => {
		overlay.classList.remove('active');
		container.classList.remove('active');
		facilityCaro.destroy();	
	}

	lightboxClose.addEventListener('click', () => {
		closeLightbox();
	});

	overlay.addEventListener('click', () => {
		closeLightbox();
	});


};


const showCaro = e => { 
	facilityCaro.destroy();
	let images = window.slide_images;
	// let background = window.getComputedStyle(e.currentTarget).backgroundImage;
	if(e){
		let clickedSlide = e.currentTarget;
		let background = window.getComputedStyle(clickedSlide).backgroundImage;
		console.log(background);
		console.log(images[0].src);
		console.log(background.search(images[0].src));
		clickedSlide = null;
		let i = 0;
		console.log('before loop');
		while(background.search(images[0].src) === -1){
			if(i > 100){break;}
			console.log('in loop');
			console.log(images[0].src);
			console.log(background);
			let t = images.shift();
			images.push(t);
			i++;
		}
		window.slide_images = images;
	}

	facilityCaro.init();
}

// window.facilityCaro = facilityCaro;
// showCaro();

export const viewBanner = (function(){

	let my = {};

	function _viewBanner() {
		const banner = document.getElementById('facility_top_banner');
		const closeBtn = document.getElementById('close_btn');

		setTimeout(() => {
			banner.classList.add('open');
		  }, "1000");

		closeBtn.addEventListener('click', function(){
			banner.classList.remove('open');
		});
	}

	my.init = function(){
		_viewBanner();
	};

	return my;
})();

/* eslint-disable no-undef */
export const facilityMap = (function(){
	let my = {},
		map = document.getElementById('facility_map');

	my.init = function(){
		if(document.getElementById('facility_map')){
			const mapHelper = getMapsHelper();
			mapHelper.ready()
				.then(() => {
					const theMap = mapHelper.createMap({
						element: map,
						locationElementSelector: '#facility_map',
						useRichmarker: true,
						markerReducer: el => {
							return {
								lat: el.getAttribute('lat'),
								lng: el.getAttribute('lng'),
								content: `
                        <span class="map_pin">
                            <svg xmlns="http://www.w3.org/2000/svg" width="43.8" height="65.65" viewBox="0 0 43.8 65.65"><g><path d="M21.9,0A21.89,21.89,0,0,0,3.54,33.83L21.9,65.65,40.27,33.83A21.91,21.91,0,0,0,21.9,0"/></g></svg>
                        </span>
                        `
							};
						}
					});

					google.maps.event.addListenerOnce(theMap, 'idle', () => {
						theMap.setZoom(15);
					});
				});
			
			[].forEach.call(document.querySelectorAll('#facility_caro_toggles .toggle'), (btn) => {
				btn.addEventListener('click', ({currentTarget}) => {
					[].forEach.call(document.querySelectorAll('#facility_caro_toggles .toggle'), (el) => {
						el.classList.remove('active');
					});
					currentTarget.classList.add('active');

					map.classList.remove('active');
					if(currentTarget.classList.contains('map')){
						map.classList.add('active');
					}
				});
			});
		}
	};

	return my;
})();

export const aspFacilityCarouselStart = _aspFacilityCarouselStart;