/* eslint-disable no-undef */
import {
	getMapsHelper
} from './util/mapsHelper';

export const maps = (function() {
	let my = {},
		multiMap = document.getElementById('locations_map'),
		mapData = document.querySelectorAll('.location_item'),
		initialized = false;

	function _startMultiMap() {
		const mapHelper = getMapsHelper();

		mapHelper.ready()
			.then(() => {
				const theMap = mapHelper.createMap({
					element: multiMap,
					locationElementSelector: '.location_item',
					useRichmarker: true,
					markerReducer: el => {
						return {
							lat: el.getAttribute('lat'),
							lng: el.getAttribute('lng'),
							content: `
							<a class="map_pin" href="${el.getAttribute('map')}" target="_blank">
								<svg xmlns="http://www.w3.org/2000/svg" width="38.66" height="55.62" viewBox="0 0 38.66 55.62"><defs><style>.cls-1{fill:#004B2A;}</style></defs><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path class="cls-1" d="M38.16,19.43c0,10.46-18.83,35.36-18.83,35.36S.5,29.89.5,19.43a18.83,18.83,0,1,1,37.66,0Z"/></g></g></svg>
								<div><span>${el.getAttribute('index')}</span></div>
							</a>
                        `
						};
					}
				});

				const infoWindow = new google.maps.InfoWindow();

				google.maps.event.addListenerOnce(theMap, 'idle', () => {
					if (mapData.length < 2) {
						theMap.setZoom(15);
					}
					[].forEach.call(mapData, i => {
						const markerData = mapHelper.getMarker(i);
						const title = i.getAttribute('title');
						const address = i.getAttribute('address');
						//const link = i.getAttribute('map');

						markerData.element.addEventListener('mouseenter', () => {
							infoWindow.setContent(`
                <div class="map_info">
                    <h3><strong style="font-weight: bold; margin-bottom:5px;">${title}</strong></h3>
                    <p style="margin-bottom:5px;">${address}</p>
                    <a href="${i.getAttribute('map')}" target="_blank">
                        <div><span>View on Google Maps</span></div>
                    </a>
                </div>
            `);
							infoWindow.open(theMap, markerData.marker);
						});

						markerData.element.addEventListener('mouseleave', () => {
							i.classList.remove('hover');
						});
					});
				});
			});
	}

	function _mapInView() {
		const rect = multiMap.getBoundingClientRect();
		const isInView = (rect.top >= 0 && rect.bottom <= ((window.innerHeight || document.documentElement.clientHeight) + 1000));
		return isInView;
	}

	function _assignListener() {
		if (_mapInView()) {
			_startMultiMap();
			initialized = true;
		} else {
			window.onscroll = function() {
				const rect = multiMap.getBoundingClientRect();
				const isInView = (rect.top >= 0 && rect.bottom <= ((window.innerHeight || document.documentElement.clientHeight) + 1000));
				if (!initialized && isInView) {
					_startMultiMap();
					initialized = true;
				}
			};
		}
	}

	my.init = function() {
		if (multiMap) {
			_assignListener();
		}
	};

	return my;
})();
