/* eslint-disable no-undef */
export const locationsRates = (function() {
	let my = {};

	// function checkSize(location, size) {
	// 	location.querySelectorAll('.lwr_units_group').forEach((unit) => {
	// 		if (unit.getAttribute('data-size') === size) {
	// 			unit.classList.toggle('active');
	// 		}
	// 	});
	// }

	my.init = function() {
		if (document.querySelector('.state_listing')) {
			const location_items = document.querySelectorAll('.location_item');
			const parser = new DOMParser();

			location_items.forEach((location) => {
				const facility_id = location.getAttribute('data-facility_id');
				const rates_cont = location.querySelector('.lwr_rates_cont');
				const rates_item = rates_cont.getElementsByTagName('div');
				const xhr = new XMLHttpRequest();
				const url = '../../facilities/rate_row_async/' + facility_id;

				xhr.open("GET", url, true);
				xhr.onreadystatechange = function() {
					if (this.readyState === 4 && this.status === 200) {
						const responseData = JSON.parse(this.responseText);
						rates_cont.appendChild(parser.parseFromString(responseData.data.lowest, "text/html").body);

						const smRates = responseData.data.sm;
						const medRates = responseData.data.med;
						const lrgRates = responseData.data.lrg;

						const lwr_rate_item = location.querySelectorAll('.lwr_rate_item ');

						lwr_rate_item.forEach((rate_item) => {
							if (rate_item.getAttribute('data-size') === 'small') {
								rate_item.appendChild(parser.parseFromString(smRates, "text/html").body);
							} else if (rate_item.getAttribute('data-size') === 'medium') {
								rate_item.appendChild(parser.parseFromString(medRates, "text/html").body);
							} else {
								rate_item.appendChild(parser.parseFromString(lrgRates, "text/html").body);
							}
						});

						if (rates_item.length > 0) {
							for (let index = 0; index < rates_item.length; index++) {
								const rate_item = rates_item[index];
								if (rate_item.classList.contains('lwr_rate_item')) {
									rate_item.addEventListener('click', (e) => {
										const size = e.currentTarget.getAttribute('data-size');
										if (!e.currentTarget.classList.contains('active')) {
											e.currentTarget.classList.add('active');
											location.querySelectorAll('.lwr_units_group').forEach((unit) => {
												if (unit.getAttribute('data-size') === size) {
													unit.classList.add('active');
													document.getElementById('main_overlay').style.display = 'block';
												}
											});
										} else {
											e.currentTarget.classList.remove('active');
											location.querySelectorAll('.lwr_units_group').forEach((unit) => {
												if (unit.getAttribute('data-size') === size) {
													unit.classList.remove('active');
													document.getElementById('main_overlay').style.display = 'none';
												}
											});
										}
									});
								}
							}
						}
					}
				};
				// Sending our request 
				xhr.send();
			});
		}
	};
	return my;
})();
