export const helpToggle = (function(){
	let my = {};

	function _assignListeners(){
		[].forEach.call(document.querySelectorAll('.help_title'), (title) => {
			title.addEventListener('click', _toggleItem);
		});
	}

	function _toggleItem(e){
		const item = e.currentTarget;
		const parentItem = e.currentTarget.parentNode;
		const helpItem = parentItem.querySelector('.help_item_content');
		const helpImg = parentItem.querySelector('.help_item_toggle');
		if(item.classList.contains('open')){
			item.classList.remove('open');
			helpItem.style.height = 0;
			helpItem.style.opacity = 0;
			helpImg.style.transform = "rotate(-90deg)";
			
		}else{
			item.classList.add('open');
			let height = parentItem.querySelector('.help_item_content_wrapper').offsetHeight;
			helpItem.style.height = height + "px";
			helpItem.style.opacity = 1;
			helpImg.style.transform = "rotate(0deg)";
		}
	}

	my.init = function(){
		if(document.getElementById('help_wrapper')) {
			_assignListeners();
			setTimeout(function(){
				document.querySelector('.help_title').click();
			}, 1000);
		}
	};

	return my;
})();
