export const parkingFeat = (function() {
	let my = {};

	my.init = function() {
		// Function to update locationDropdown based on selectedState
		function updateLocationDropdown(selectedState) {
			const locationDropdown = document.getElementById('locationDropdown');
			const options = locationDropdown.getElementsByTagName('option');
			let selectedIndex = -1;

			for (let i = 0; i < options.length; i++) {
				if (options[i].getAttribute('id') === selectedState) {
					options[i].style.display = 'block';
					if (selectedIndex === -1) {
						selectedIndex = i; // Set selectedIndex to the first visible item
					}
				} else {
					options[i].style.display = 'none';
				}
			}

			locationDropdown.selectedIndex = selectedIndex;

			// Trigger change event manually to update facility details
			const changeEvent = new Event('change');
			locationDropdown.dispatchEvent(changeEvent);
		}

		if (document.getElementById('stateDropdown')) {
			document.getElementById('stateDropdown').addEventListener('change', function(event) {
				let selectedState = event.target.value;
				updateLocationDropdown(selectedState);
			});
		}

		if (document.getElementById('locationDropdown')) {
			document.getElementById('locationDropdown').addEventListener('change', function(event) {
				const selectedFacility = event.target.value;
				const fac_state = document.getElementById('fac_state');
				const fac_city = document.getElementById('fac_city');
				const fac_name = document.getElementById('fac_name');
				const fac_address = document.getElementById('fac_address');
				const fac_url = document.getElementById('fac_url');

				const facility = JSON.parse(selectedFacility);

				fac_state.innerHTML = facility.state;
				fac_city.innerHTML = facility.city;
				fac_name.innerHTML = facility.name;
				fac_address.innerHTML = facility.address;
				fac_url.style.display = 'flex';
				fac_url.href = facility.url;
			});
		}

		// Call the updateLocationDropdown function on page load with the initially selected state
		if (document.getElementById('stateDropdown')) {
			const initialState = document.getElementById('stateDropdown').value;
			updateLocationDropdown(initialState);
		}
	};

	return my;
})();
